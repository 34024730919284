import React from 'react'

export const Error = () => {
  return (
    <div className='error-page'>
        <h1>Error:</h1>
        <p>Sorry, this page does not exist</p>

    </div>
  )
}
